import './about.scss';
import Button from "../button/button";
import React, { useState, useEffect } from 'react';

function About() {
    const [yearsCount, setYearsCount] = useState(0);
    const [childrenCount, setChildrenCount] = useState(0);
    const [projectsCount, setProjectsCount] = useState(0);
    const [successRate, setSuccessRate] = useState(0);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            if (yearsCount < 8) {
                setYearsCount(prevCount => prevCount + 1);
            } else {
                clearInterval(interval);
            }
        }, 200);

        return () => {
            clearInterval(interval);
        };
    }, [yearsCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (childrenCount < 45000) {
                setChildrenCount(prevCount => prevCount + 20);
            } else {
                clearInterval(interval);
            }
        }, 0.01);

        return () => {
            clearInterval(interval);
        };
    }, [childrenCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (projectsCount < 3600) {
                setProjectsCount(prevCount => prevCount + 3);
            } else {
                clearInterval(interval);
            }
        }, 0.1);

        return () => {
            clearInterval(interval);
        };
    }, [projectsCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (successRate < 3600) {
                setSuccessRate(prevRate => prevRate + 3);
            } else {
                clearInterval(interval);
            }
        }, 0.1);

        return () => {
            clearInterval(interval);
        };
    }, [successRate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = document.createElement('form');
        form.action = 'https://docs.google.com/forms/d/e/1FAIpQLScO4p5lNhe5eUsjddEeocTBM0bofy2GPh3oF9rck6NqZ7vDuQ/formResponse';
        form.method = 'POST';
        form.target = 'hidden_iframe';

        const nameField = document.createElement('input');
        nameField.type = 'hidden';
        nameField.name = 'entry.512631380';
        nameField.value = name;
        form.appendChild(nameField);

        const phoneField = document.createElement('input');
        phoneField.type = 'hidden';
        phoneField.name = 'entry.361302695';
        phoneField.value = phone;
        form.appendChild(phoneField);

        const cityField = document.createElement('input');
        cityField.type = 'hidden';
        cityField.name = 'entry.510362471';
        cityField.value = city;
        form.appendChild(cityField);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        alert('Ваші дані були відправлені!');
        setName('');
        setPhone('');
        setCity('');
    };

    return (
        <section className="section about">
            <div className="container about__container">
                <div className="about__text-content">
                    <h1 className="about__title">
                        Курси програмування для дітей від 8-14 років
                    </h1>
                    <h3 className="about__sub-title">
                        Допомагаємо школярам полюбити навчання та програмування
                    </h3>
                    <h3 className="about__sub-title-active">
                        Спробуйте перший урок <span>БЕЗКОШТОВНО</span>
                    </h3>
                </div>
                <form className="about__form" onSubmit={handleSubmit}>
                    <div className="about__form-container">
                        <input
                            type="text"
                            placeholder="Ім'я"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="tel"
                            placeholder="Номер телефону"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Місто"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <Button
                            classNameBtn={'about__form-btn'}
                            textContentBtn={'Відправити'}
                        />
                    </div>
                </form>
                <div className="about__feedback">
                    <div className="about__feedback-content">
                        <p>
                            <span>{yearsCount}+</span>
                            років навчаємо дітей
                        </p>
                    </div>
                    <div className="about__feedback-content">
                        <p>
                            <span>{childrenCount}+</span>
                            дітей навчались у нас
                        </p>
                    </div>
                    <div className="about__feedback-content">
                        <p>
                            <span>{projectsCount}+</span>
                            проєктів створено
                        </p>
                    </div>
                    <div className="about__feedback-content">
                        <p>
                            <span>{successRate}+</span>
                            покращили успішність
                        </p>
                    </div>
                </div>
            </div>
            <iframe name="hidden_iframe" style={{ display: 'none' }}></iframe>
        </section>
    )
}

export default About;
