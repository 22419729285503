// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-4 {
  background-color: rgb(31, 120, 31);
  background-image: linear-gradient(315deg, rgb(31, 120, 31) 0%, #a8e496 74%);
  line-height: 42px;
  padding: 5px 40px;
  border: none;
  border-radius: 15px;
  color: rgb(0, 0, 0);
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  transition: all 1s;
  cursor: pointer;
  transition: 1 alls;
}
.btn-4:hover {
  background-color: #2be443;
  transition: 1s;
  background-image: linear-gradient(315deg, #5de83e 0%, #03c8a8 74%);
  transition: linear 1s;
}`, "",{"version":3,"sources":["webpack://./src/component/button/button.scss","webpack://./src/component/varible.scss"],"names":[],"mappings":"AAGA;EACI,kCCGS;EDFT,2EAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,mBCJS;EDKT,qBCVe;EDWf,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AAFJ;AAII;EACI,yBAAA;EACA,cAAA;EACA,kEAAA;EACA,qBAAA;AAFR","sourcesContent":["@import '../varible.scss';\n@import '../varible.scss';\n\n.btn-4 {\n    background-color: $ColorGreen;\n    background-image: linear-gradient(315deg, $ColorGreen 0%, #a8e496 74%);\n    line-height: 42px;\n    padding: 5px 40px;\n    border: none;\n    border-radius: 15px;\n    color: $ColorBlack;\n    font-family: $HeaderFontFamily;\n    font-size: 18px;\n    font-weight: 500;\n    transition: all 1s;\n    cursor: pointer;\n    transition: 1 alls;\n\n    &:hover {\n        background-color: #2be443;\n        transition: 1s;\n        background-image: linear-gradient(315deg, #5de83e 0%, #03c8a8 74%);\n        transition: linear 1s;\n    }\n}","// Fonts \n$HeaderFontFamily: 'Roboto';\n\n\n// Colors\n$ColorWhite: rgb(255, 255, 255);\n$ColorBlack: rgb(0, 0, 0);\n$ColorGreen: rgb(31, 120, 31);\n$ColorGrey: rgb(183, 183, 183);\n$ColorBackGround : rgb(235, 253, 235);\n\n// calc header and footer\n$HeaderHeigth: 127px;\n$FooterHeigth: 258px;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
