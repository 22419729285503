import React, { useState } from 'react';
import './contact-us.scss';
import Inst from '../image/inst.svg';
import Viber from '../image/viber.svg';
import Facebook from '../image/fcbk.svg';
import Tel from '../image/tel.svg';
import Button from '../button/button.jsx';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = document.createElement('form');
        form.action = 'https://docs.google.com/forms/d/e/1FAIpQLScO4p5lNhe5eUsjddEeocTBM0bofy2GPh3oF9rck6NqZ7vDuQ/formResponse';
        form.method = 'POST';
        form.target = 'hidden_iframe';

        const nameField = document.createElement('input');
        nameField.type = 'hidden';
        nameField.name = 'entry.512631380';
        nameField.value = name;
        form.appendChild(nameField);

        const phoneField = document.createElement('input');
        phoneField.type = 'hidden';
        phoneField.name = 'entry.361302695';
        phoneField.value = phone;
        form.appendChild(phoneField);

        const cityField = document.createElement('input');
        cityField.type = 'hidden';
        cityField.name = 'entry.510362471';
        cityField.value = city;
        form.appendChild(cityField);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);

        alert('Ваші дані були відправлені!');
        setName('');
        setPhone('');
        setCity('');
    };

    return (
        <section className={"contact-us section"}>
            <div className={"container contact-us__container"}>
                <div className={"contact-us__content"}>
                    <h3 className={"contact-us__title"}>
                        Наші <span>контакти</span>
                    </h3>
                    <div className='contact-us__social' >
                        <p className='contact-us__sub-title'>
                            +38(095)186-01-78
                        </p>
                        <p className='contact-us__sub-title'>
                            +38(097)295-27-47
                        </p>
                    </div>
                    <div className='contact-us__social' >
                        <p className='contact-us__sub-title'>
                        interwebschool@gmail.com
                        </p>
                    </div>
                </div>
                <div className={"contact-us__form"}>
                    <h4 className='form-title'>
                        Залиште свої <span>контакти</span>, і ми допоможемо вирішити будь-яке питання.
                    </h4>
                    <form className={"form"} onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Ім'я"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="tel"
                            placeholder="Номер телефону"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Місто"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                        <div className='contact-us__form-button'>
                            <Button
                                textContentBtn={'Відправити'}
                                classNameBtn={'contact-us__form-btn'}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <iframe name="hidden_iframe" style={{ display: 'none' }}></iframe>
        </section>
    );
};

export default ContactUs;
