// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
}

li,
a {
  list-style: none;
  text-decoration: none;
}

body {
  font-family: "Roboto";
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.container {
  max-width: 1300px;
  padding: 0px 15px 0px 15px;
  margin: 0 auto;
}
@media (max-width: 1000px) {
  .container {
    max-width: 700px;
  }
}
@media (max-width: 700px) {
  .container {
    max-width: 500px;
  }
}`, "",{"version":3,"sources":["webpack://./src/global.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,SAAA;EACA,sBAAA;AACJ;;AAGA;EACI,cAAA;EACA,WAAA;AAAJ;;AAGA;;EAEI,gBAAA;EACA,qBAAA;AAAJ;;AAGA;EACI,qBAAA;EACA,uBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,iBAAA;EACA,0BAAA;EACA,cAAA;AAAJ;AAEI;EALJ;IAMQ,gBAAA;EACN;AACF;AAEI;EAVJ;IAWQ,gBAAA;EACN;AACF","sourcesContent":["* {\n    padding: 0;\n    margin: 0;\n    box-sizing: border-box;\n}\n\n\nimg {\n    display: block;\n    width: 100%;\n}\n\nli,\na {\n    list-style: none;\n    text-decoration: none;\n}\n\nbody {\n    font-family: 'Roboto';\n    scroll-behavior: smooth;\n    overflow-x: hidden;\n}\n\n.container {\n    max-width: 1300px;\n    padding: 0px 15px 0px 15px;\n    margin: 0 auto;\n\n    @media (max-width: 1000px) {\n        max-width: 700px;\n\n    }\n\n    @media (max-width: 700px) {\n        max-width: 500px;\n\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
