import ImageHtml from "../image/html.png";
import ImageCss from "../image/css.png";
import ImageJS from "../image/js.png";
import ImagePy from "../image/py.png";
import ImageMySql from "../image/mysqllogo.png";
import ImageRoblox from "../image/roblox.png";
import User1 from "../image/Mogilko-768x512.jpg";
import User2 from "../image/Odrinskiy-768x535.jpg";
import User3 from "../image/sofa-682x1024.jpg";
import UserFeedback from "../image/14.jpg";
import UserFeedback2 from "../image/18.jpg";
import UserFeedback3 from "../image/52.jpg";
import UserFeedback4 from "../image/avatar4.jpg";
import UserFeedback5 from "../image/avatar39.jpg";
import UserFeedback6 from "../image/avatar3.jpg";
import UserFeedback7 from "../image/74.jpg";
import UserFeedback8 from "../image/sss.jpg";
import UserFeedback9 from "../image/avatar2.jpg";
import UserFeedback10 from "../image/ssss.jpg";
import UserFeedback11 from "../image/sssss.png";
import UserFeedback12 from "../image/ssssss.jpg";
import UserFeedback13 from "../image/sssssss.jpg";
import UserFeedback14 from "../image/aa.jpg";
import UserFeedback15 from "../image/aaa.jpg";
import UserFeedback16 from "../image/aaaaa.jpg";
import UserFeedback17 from "../image/assass.jpg";
export const data = [
  {
    id: 1,
    age: "Roblox",
    title: "Junior",
    discriotion:
      "Junior рік - Розробник ігор Roblox – це творчий спеціаліст, який володіє навичками створення ігор та інтерактивних світів у популярному середовищі Roblox. Якщо ви прагнете, аби ваша дитина з дитинства занурилася у світ гейм-дизайну та програмування, то наш курс “Програмування у Roblox” у школі Inter-web стане ідеальним вибором. Курс рекомендовано: 3-11 клас.",
    image: ImageRoblox,
  },
  {
   
    id: 2,
    title: "Junior advanced",
    age: "HTML&CSS",
    discriotion:
      "Junior advanced рік — це вивчення мов HTML та CSS.  Веб-розробник – це універсальний спеціаліст, який забезпечує створення зовнішнього вигляду та логіку роботи сайту. Якщо ви хочете, щоб Ваша дитина з ранніх років почала робити перші кроки до затребуваної професії веб-розробника, тоді курс HTML та CSS у школі Inter-web саме для вас. Курс рекомендовано: 5-11 клас.",
    image: ImageHtml,  
},
  {
    id: 3,
    title: "Middle",
    age: "JavaScript",
    discriotion:
      "Middle рік – це вивчення мови JavaScript. Більшість сучасних сайтів містять такі інтерактивні елементи, як анімація, спливаючі вікна, підказки, меню, фотогалереї тощо. – всі ці елементи роблять сайти привабливими та зручними для користувача. За таку взаємодію веб-сайтів з відвідувачем відповідає сценарна мова програмування JavaScript. Курс рекомендовано: 7-11 клас.",
    image: ImageCss,
  },
  {
    id: 4,
    title: "Middle advanced",
    age: "Python&PHP",
    discriotion:
      "Middle advanced рік – це вивчення мов Python та PHP. Мову програмування Python використовують майже у всіх IT сферах. Він знадобиться у розробці WEB-додатків, при написанні чат-ботів та штучного інтелекту, для створення ігор, програм та скриптів для автоматизації та системного адміністрування. Курс рекомендовано: 7-11 клас.",
    image: ImageJS,
  },
  {
    id: 5,
    age: "Advanced PHP&MySQL",
    title: "Senior",
    discriotion:
      "Senior рік – це вивчення продвинутого рівня PHP та роботи з базами даних MySQL. PHP – це потужна мова програмування, призначена спеціально для розробки веб-додатків. На цьому етапі курсу ми поглиблюємо знання PHP, фокусуючись на складніших техніках та практиках, які використовуються у професійній веб-розробці. Крім того, учні вивчають роботу з MySQL – найпопулярнішою системою управління базами даних для веб-додатків. Курс рекомендовано: 7-11 клас.",
    image: ImagePy,
  },
  
  //{
  //  id: 5,
  //  age: "6-9 клас",
  //  title: "Roblox",
  //  discriotion:
  //    "В айті - школі ми досліджуємо світ програмування за допомогою Роблокса! Тут ти зможеш створити власні ігри, додавати веселі функції та навіть розробляти свої власні історії.Забудь про границі - на Роблоксі можна втілити будь - які ідеї та зробити своє імя в світі розробників. Готуйся до захоплюючого подорожі у світ програмування!",
  //  image: ImageRoblox,
  //},
];

export const dataStudents = [
  {
    id: 1,
    Name: "Денис",
    Image: User1,
    Discriotion:
      "Мені 15 років і я живу і вчуся в місті Красноград, зараз активно вивчаю веб-програмування, працюю з мовою текстової розмітки HTML & CSS, створюю сайти за допомогою мови JS.",
  },

  {
    id: 2,
    Name: "Богдан",
    Image: User2,
    Discriotion:
      "Мені 15 років я живу і вчуся в місті Краснограді. Захоплююся вивченням мов програмування. Працюю і створюю сайти за допомогою мови текстової розмітки HTML & CSS і JS. Готовий взяти замовлення на розробку сайту.",
  },

  {
    id: 3,
    Name: "Софія",
    Image: User3,
    Discriotion:
      "Я опанувала HTML та такі мови програмування, як: JavaScript, CSS – почала вивчати Python. Вмію користуватися бібліотекою jQueri. Мої хоббі: програмування та малювання.",
  },
];

export const dataFeedBack = [
  {
    Name: "Марія, 32 роки:",
    Discriotion:
      '"Школа Inter-Web - відмінна платформа для моїх дітей! Вони навчилися створювати власні ігри на Roblox та ефективно використовувати PHP та MySQL. Вражена їх розвитком!"',
    Avatar: UserFeedback,
  },
  {
    Name: "Ігор, 14 років:",
    Discriotion:
      '"Школа Inter-Web - відмінна платформа для моїх дітей! Вони навчилися створювати власні ігри на Roblox та ефективно використовувати PHP та MySQL. Вражена їх розвитком!"',
    Avatar: UserFeedback2,
  },
  {
    Name: "Тетяна, 38 років:",
    Discriotion:
      '"Мої діти закохані в навчання в Inter-Web. Вони освоїли PHP та MySQL настільки ефективно, що вже розробляють власні програми. Це дійсно вражаюче!"',
    Avatar: UserFeedback3,
  },
  {
    Name: "Олексій, 16 років:",
    Discriotion:
      '"Інтерактивні уроки з Roblox дали мені величезний заряд енергії! Inter-Web - краще місце для вивчення програмування та грифічного дизайну."',
    Avatar: UserFeedback4,
  },
  {
    Name: "Наталія, 29 років:",
    Discriotion:
      '"Мої діти вчаться тут і неймовірно задоволені. Їм вдалося розкрити свій творчий потенціал завдяки урокам HTML та CSS. Рекомендую Inter-Web всім батькам!"',
    Avatar: UserFeedback5,
  },
  {
    Name: "Володимир, 13 років:",
    Discriotion:
      '"PHP та MySQL були складними, але цікавими! Inter-Web допоміг мені зрозуміти, як створювати свої власні веб-сайти. Неймовірно круто!"',
    Avatar: UserFeedback6,
  },
  {
    Name: "Ольга, 35 років:",
    Discriotion:
      '"Моя донька просто захоплена уроками в Inter-Web. Вивчаючи HTML та CSS, вона вже розробила власний блог. Тут справжні професіонали викладають!"',
    Avatar: UserFeedback7,
  },
  {
    Name: "Андрій, 15 років:",
    Discriotion:
      '"Roblox - це щось неймовірне! Уроки в Inter-Web з цієї гри допомогли мені зрозуміти, як створювати власні веселі світи. Дякую за можливість!"',
    Avatar: UserFeedback8,
  },
  {
    Name: "Ірина, 40 років:",
    Discriotion:
      '"Син захоплюється навчанням в Inter-Web. Я вражена, як він легко оволодіває різними технологіями - від HTML до MySQL. Професійні викладачі роблять свою справу чудово!"',
    Avatar: UserFeedback10,
  },
  {
    Name: "Владислав, 17 років:",
    Discriotion:
      '"Інтерактивні уроки з PHP в Inter-Web - це саме те, що мені потрібно було для розвитку своїх програмістських навичок. Рекомендую!"',
    Avatar: UserFeedback11,
  },
  {
    Name: "Тетяна, 33 роки:",
    Discriotion:
      '"Мій син вже вміє створювати власні ігри на Roblox завдяки курсам в Inter-Web. Я вражена його досягненнями. Дякуємо за теплу атмосферу!"',
    Avatar: UserFeedback12,
  },
  {
    Name: "Максим, 14 років:",
    Discriotion:
      '"Школа Inter-Web - відмінна платформа для моїх дітей! Вони навчилися створювати власні ігри на Roblox та ефективно використовувати PHP та MySQL. Вражена їх розвитком!"',
    Avatar: UserFeedback13,
  },
  {
    Name: "Анастасія, 31 рік:",
    Discriotion:
      '"Моя донька вже знає, як працювати з базами даних за допомогою MySQL, навчаючись в Inter-Web. Розвиваюча програма та професійні викладачі - найкращі!"',
    Avatar: UserFeedback14,
  },
  {
    Name: "Євген, 16 років:",
    Discriotion:
      '"Roblox - це круто! Навчання в Inter-Web допомогло мені створити власний геймплей та збільшити свої навички програмування. Рекомендую!"',
    Avatar: UserFeedback15,
  },
  {
    Name: "Маргарита, 37 років:",
    Discriotion:
      '"Школа Inter-Web дала моєму синові можливість реалізувати свої творчі ідеї. HTML, CSS, PHP - він вже вправний у цих технологіях!"',
    Avatar: UserFeedback16,
  },
  {
    Name: "Олександр, 18 років:",
    Discriotion:
      '"Навчання в Inter-Web дало мені шанс поглибити знання в галузі PHP та MySQL. Тепер я готовий до викликів сучасного світу програмування',
    Avatar: UserFeedback17,
  },
];
